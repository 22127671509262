<template>
  <section v-if="orders && orders.length > 0">
    <!--<template v-if="!$vuetify.breakpoint.sm"> -->

    <!-- filter/sort -->
    <v-row class="pt-2">
      <!-- search bar -->
      <v-col cols="4">
        <v-form @submit.prevent="searchOrders">
          <v-text-field
            v-model="search_key"
            label="Search"
            append-icon="search"
          ></v-text-field>
        </v-form>
      </v-col>

      <v-col cols="4">
        <!-- date sort -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn top text color="grey" @click="sortByDate" v-on="on">
              <v-icon left>date_range</v-icon>
              <span class="text-capitalize">Date</span>
            </v-btn>
          </template>
          <span>Sort Orders by Date</span>
        </v-tooltip>
        <!-- sort by name -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn top text color="grey" @click="sortByName" v-on="on">
              <v-icon left>person</v-icon>
              <span class="text-capitalize">Name</span>
            </v-btn>
          </template>
          <span>Sort Orders by Name</span>
        </v-tooltip>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="filter_list"
          prepend-icon="filter_list"
          v-model="filter"
          @change="filterList"
          label="Filter"
        ></v-select>
      </v-col>
    </v-row>

    <!-- shows this card if there are no results after filter -->
    <v-card v-if="noResults">
      <v-card-title primary-title class="layout justify-center">
        <div class="headline grey--text">
          There are no results for this filter.
        </div>
      </v-card-title>
    </v-card>

    <!-- ORDER CARDS -->
    <v-card flat>
      <vue-perfect-scrollbar :settings="settings">
        <v-list dense>
          <v-list-group
            v-for="(order, index) in mutableOrders"
            class="ma-0 pa-0"
            :key="order.id"
            value="true"
            :class="index % 2 == 0 ? 'grey lighten-4' : null"
            ripple
          >
            <template v-slot:activator>
              <!-- MAIN CONTENT -->
              <v-row align="center">
                <v-col cols="5" sm="3">
                  <h4 class="mb-1">{{ order.foodName }}</h4>
                  <span class="grey--text" v-if="today(order)">
                    <span
                      :class="order.orderType === 'later' ? ' title' : null"
                      v-if="order.deliveryDateTime"
                      >Today {{ order.deliveryDateTime | formatTimeOnly }}
                    </span>
                  </span>
                  <span class="grey--text" v-else>
                    <span
                      :class="order.orderType === 'later' ? ' title' : null"
                      v-if="order.deliveryDateTime"
                      >{{ $moment(order.deliveryDateTime).format("LT") }}
                      <small>{{
                        $moment(order.deliveryDateTime).format("MMM DD")
                      }}</small>
                    </span>
                  </span>
                  <br />
                  <span
                    class="brown--text"
                    v-if="
                      showOrderDetail(order) &&
                        order.payment &&
                        order.payment.all_fees
                    "
                  >
                    <small v-if="isChef">
                      {{ $t("Common.total") }}:
                      {{ order.payment.all_fees.producer_total | currency }}
                    </small>
                    <small v-else>
                      {{ $t("Common.total") }}:
                      {{ order.payment.all_fees.total | currency }}
                    </small>
                  </span>
                </v-col>
                <v-col cols="1">
                  <router-link
                    :to="'/order/view/' + order.id + '?source=' + orderSource"
                  >
                    <v-icon>edit</v-icon>
                  </router-link>
                </v-col>
                <v-col cols="6" sm="3">
                  <router-link
                    :to="'/order/view/' + order.id + '?source=' + orderSource"
                  >
                    {{ order.id.substr(0, 4) }}
                    <span class="fs-14">for: {{ order.buyer.name }}</span>
                  </router-link>
                </v-col>
                <v-col cols="5" sm="3">{{
                  order.deliveryMethod | capitalize
                }}</v-col>

                <v-col cols="5" sm="2" v-if="showOrderDetail(order)">
                  <router-link
                    :to="'/order/view/' + order.id + '?source=' + orderSource"
                  >
                    <v-avatar class="hidden-sm-and-down mr-2">
                      <v-icon :color="icon(order.status).color">
                        {{ icon(order.status).icon }}
                      </v-icon>
                    </v-avatar>
                    <span :class="icon(order.status).color + '--text'">
                      {{ title(order.status) | capitalize }}
                    </span>
                  </router-link>
                  <small class="pl-1 font-italic">
                    {{ order.cooking_status | capitalize }}
                  </small>
                </v-col>

                <v-col
                  cols="3"
                  v-else-if="order.seller"
                  class="w-10 d-custom-flex justify-space-between align-center badge-wrap"
                >
                  <v-tooltip top v-if="order.seller">
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on" size="40px">
                        <img
                          v-if="order.seller.avatar"
                          slot="activator"
                          :src="order.seller.avatar"
                          class="hidden-sm-and-down img-responsive"
                          alt="user images"
                        />
                      </v-avatar>
                    </template>
                    <span>{{ order.seller.name }}</span>
                  </v-tooltip>
                  <span v-if="!order.seller.avatar">{{
                    order.seller.name
                  }}</span>
                </v-col>
              </v-row>
            </template>

            <!-- EXPANDABLE -->
            <v-container>
              <v-row
                class="mr-2 b-1"
                v-if="showOrderDetail(order)"
                @click="gotoOrder(order)"
              >
                <!-- product cards -->
                <v-col cols="12" sm="4" md="3">
                  <v-card outlined shaped :color="statusColor(order)">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="title mb-4">
                          {{
                            order.orderType === "later" ? "Pre-Order" : "Now"
                          }}
                          for {{ order.deliveryMethod }}
                        </div>
                        <v-list-item-title
                          class="subtitle-2 mb-1"
                          v-if="isChef && order.acceptDate"
                        >
                          Order Accepted at: <br />
                          {{ order.acceptDate | formatTimeDate }}
                        </v-list-item-title>

                        <v-list-item-title
                          class="subtitle-2 mb-1"
                          v-else-if="isChef && order.orderType === 'later'"
                        >
                          Start Cooking at:<br />
                          {{ startCooking(order) }}
                        </v-list-item-title>

                        <v-list-item-subtitle></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>

                <template v-for="product in order.products">
                  <v-col
                    cols="12"
                    sm="6"
                    md="5"
                    lg="4"
                    xl="3"
                    class="ma-1"
                    :key="product.dish.id"
                  >
                    <OrderItemCard :product="product" :isChef="isChef" />
                  </v-col>
                </template>
                <template>
                  <v-col cols="12" sm="3">
                    <v-alert
                      v-show="order.notes"
                      border="left"
                      colored-border
                      type="info"
                      elevation="2"
                      >{{ order.notes }}</v-alert
                    >
                  </v-col>
                </template>

                <!-- add note card -->
              </v-row>
              <v-row
                class="mr-2"
                justify="center"
                align="center"
                v-else
                @click="gotoOrder(order)"
              >
                <v-col cols="4">
                  <h1 :class="icon(order.status).color + '--text display-1 '">
                    {{ title(order.status) | capitalize }}
                  </h1>
                </v-col>
              </v-row>

              <!-- chef new order actions -->
              <!-- changeOrderStatus PARAM: order:object and order status: string-->
              <v-row align="center" v-if="status === 'new' && isChef">
                <v-col cols="5" sm="2">
                  <v-btn large color="primary" @click="acceptOrder(order)"
                    >Accept</v-btn
                  >
                </v-col>
                <v-col
                  cols="5"
                  sm="4"
                  v-if="
                    status === 'new' &&
                      isChef &&
                      order.deliveryMethod === 'delivery'
                  "
                >
                  <v-switch
                    v-if="false"
                    label="Touch Free Delivery"
                    color="info"
                    :value="true"
                  ></v-switch>
                </v-col>

                <v-col cols="4" sm="3">
                  <v-btn small @click="showReject(order)" color="error"
                    >Reject</v-btn
                  >
                </v-col>
                <v-col cols="1" sm="1">
                  <v-row justify="end">
                    <v-col cols="1">
                      <v-btn small text @click="toMessages(order.uid)">
                        <v-badge color="orange">
                          <!-- hasNewMessage PARAMS: order user id: string -->
                          <template v-if="hasNewMessage(order.uid)" v-slot:badge
                            >!</template
                          >
                          <v-icon color="grey">message</v-icon>
                        </v-badge>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- chef active order actions -->
              <!-- updateCookingStatus PARAM: order id: string and cooking status: string -->
              <v-row align="center">
                <v-col cols="6" sm="3" md="2">
                  <template v-if="showCookingStatus(order)">
                    <v-btn
                      small
                      @click="updateCookingStatus(order.id, 'cooked')"
                    >
                      <v-icon small color="grey">restaurant_menu</v-icon>Cooking
                    </v-btn>
                    <v-btn
                      small
                      v-if="false"
                      @click="updateCookingStatus(order.id, 'ready')"
                      class="ml-2"
                    >
                      <v-icon small color="grey">room_service</v-icon>Ready
                    </v-btn>
                  </template>
                </v-col>
                <template v-if="showDeliveryActions(order)">
                  <v-col
                    cols="3"
                    sm="4"
                    md="3"
                    v-if="showReadyForDelivery(order)"
                  >
                    <v-btn
                      large
                      :disabled="
                        order.delivery.action === 'submitting_delivery' ||
                          order.delivery.action === 'delivery_submitted'
                      "
                      color="success"
                      @click="onReadyForDelivery(order)"
                      >SEND DRIVER NOW

                      <v-icon
                        right
                        v-if="order.delivery.action === 'delivery_failed'"
                        color="orange lighten-1"
                        >error</v-icon
                      >
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="8"
                    sm="4"
                    md="4"
                    v-if="!showReadyForDelivery(order)"
                  >
                    <v-btn
                      v-if="order.deliveryMethod === 'pickup'"
                      color="info"
                      small
                      class="mr-2"
                      :disabled="onDeliveryStaus(order)"
                      raised
                      @click.stop="setStatus(order, true)"
                    >
                      {{ deliveryTitle(order) }}
                      <v-icon dark right>local_shipping</v-icon>
                    </v-btn>

                    <v-btn
                      color="secondary"
                      small
                      :disabled="onNoDeliveryStaus(order)"
                      @click.stop="setStatus(order, false)"
                      v-if="isChef"
                    >
                      {{ noDeliveryTitle(order) }}
                      <v-icon dark right>block</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="1">
                    <v-row justify="end">
                      <v-col cols="6" md="2">
                        <v-btn small text @click="toMessages(order.uid)">
                          <v-badge color="orange">
                            <!-- hasNewMessage PARAMS: order user id: string -->
                            <template
                              v-if="hasNewMessage(order.uid)"
                              v-slot:badge
                              >!</template
                            >
                            <v-icon color="grey">message</v-icon>
                          </v-badge>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>

              <v-alert
                v-if="order.error || order.chefResponse"
                type="error"
                outlined
                :value="true"
              >
                {{ order.error ? order.error : "" }}
                <br v-if="order.error && order.chefResponse" />
                {{
                  order.chefResponse
                    ? "Chef's Response: " + order.chefResponse
                    : ""
                }}
              </v-alert>
            </v-container>
          </v-list-group>
        </v-list>
      </vue-perfect-scrollbar>
    </v-card>

    <!-- dialog for invalid accept -->
    <v-dialog v-model="showInvalidAccept" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon color="grey">error</v-icon>
          {{ errorMsg }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showInvalidAccept = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ask for rejection reason -->
    <v-dialog v-model="rejectDialog" width="500px">
      <v-card>
        <v-card-title>Reject Order</v-card-title>
        <v-card-text>
          <v-text-field
            maxlength="250"
            label="Reason"
            v-model="rejectResponse"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn dark color="red" @click.stop="rejectOrder">
            Reject
            <v-icon dark right>cancel</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            text
            @click.stop="(rejectDialog = false), (tempRejectOrder = null);"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deliveryDialog" max-width="600">
      <v-card v-if="selected_order">
        <v-card-title class="headline"
          >Delivery Request #{{ selected_order.id.substr(0, 4) }} for:
          {{ selected_order.buyer.name }}</v-card-title
        >
        <v-card-text>
          <v-icon color="info">phone</v-icon> {{ selected_order.buyer.phone }}
        </v-card-text>

        <v-row>
          <v-col cols="11" v-if="selected_order_diff.message">
            <v-alert
              outlined
              :type="selected_order_diff.allow ? 'info' : 'error'"
              dense
              >{{ selected_order_diff.message }}</v-alert
            >
          </v-col>
          <v-col
            cols="11"
            v-if="selected_order && selected_order.delivery.error"
          >
            Delivery Error:
            <v-alert outlined type="error" dense>{{
              selected_order.delivery.error
            }}</v-alert>
          </v-col>
        </v-row>

        <v-card-text v-if="selected_order_diff.allow"
          >You are about to submit delivery request.</v-card-text
        >
        <v-card-actions>
          <v-text-field
            v-if="selected_order_diff.ask"
            label="Confirm"
            v-model="confrim_text"
            placeholder="OK"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="
              confrim_text.toLowerCase() !== 'ok' && !selected_order_diff.allow
            "
            @click="setReadyForDelivery"
            >Send Driver</v-btn
          >
          <v-btn
            v-if="!selected_order_diff.allow"
            color="grey"
            dark
            @click="archive(selected_order)"
            >Archive</v-btn
          >
          <v-btn @click="deliveryDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
  <section v-else>
    <v-card>
      <v-card-title primary-title>No active orders</v-card-title>
    </v-card>
  </section>
</template>

<script>
  import { OrderMixin } from "../../mixins/orderMixin";
  import OrderItemCard from "../order/OrderItemCard";
  import { mapGetters } from "vuex";
  import moment from "moment";

  export default {
    props: ["orders", "orderSource", "mobile", "isChef", "status"],
    mixins: [OrderMixin],
    components: { OrderItemCard },
    data() {
      return {
        settings: {
          maxScrollbarLength: 100
        },
        activeOnly: false,
        mutableOrders: this.orders,
        sortOldToNew: true,
        sortAtoZ: false,
        filter: null,
        filter_list: [
          "None",
          "New",
          "Accepted",
          "Delivered",
          "Rejected",
          "Cancelled"
        ],
        noResults: false,
        search_key: null,
        showInvalidAccept: false,
        errorMsg: null,
        rejectDialog: false,
        deliveryDialog: false,
        rejectResponse: null,
        tempRejectOrder: null,
        selected_order_diff: {},
        selected_order: null,
        confrim_text: ""
      };
    },
    computed: {
      ...mapGetters({
        bankAccount: "bankAccount",
        orderMessages: "orderMessages",
        profile: "profile"
      })
    },
    mounted() {
      this.$store.dispatch("loadOrderMessages");
    },
    watch: {
      orders: function() {
        // sort recent first
        this.mutableOrders = this.orders;
      }
    },
    methods: {
      // returns list of all modifiers for the order
      getModifiers(order) {
        let modifier_list = {};
        let modifersExist = false;
        if (order.products) {
          order.products.forEach((product) => {
            if (product.modifiers) {
              modifersExist = true;
              Object.entries(product.modifiers).forEach(
                ([key, value]) => (modifier_list[key] = value)
              );
            }
          });
        }
        // returns null if object is empty
        if (modifersExist) {
          return modifier_list;
        } else {
          return null;
        }
      },
      orderDate(order) {
        //return this.$moment(order.deliveryDate + ' '+order.deliveryTime).fromNow()
        return this.$moment(order.deliveryDateTime).fromNow();
        /* return order.requestedDeliveryTime
        ? this.$moment(order.requestedDeliveryTime).fromNow()
        : null */
      },
      // sorts orders by date
      sortByDate() {
        if (this.sortOldToNew) {
          // sets from old to new (recent first)
          this.mutableOrders.sort((a, b) =>
            a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
          );
        } else {
          // sets from new to old (old first)
          this.mutableOrders.sort((a, b) =>
            a.deliveryDateTime < b.deliveryDateTime ? -1 : 1
          );
        }

        // to reset the name sort
        this.sortAtoZ = false;
        this.sortOldToNew = !this.sortOldToNew;
      },
      sortByName() {
        if (this.sortAtoZ) {
          // sets from A to Z (un-alphabetical)
          this.orders.sort((a, b) => (a.buyer.name > b.buyer.name ? -1 : 1));
        } else {
          // sets from Z to A (alphabetical)
          this.orders.sort((a, b) => (a.buyer.name < b.buyer.name ? -1 : 1));
        }
        // to reset the time sort, needs to be set to true because the list comes sorted by time
        this.sortOldToNew = true;
        this.sortAtoZ = !this.sortAtoZ;
      },
      // filters orders based on the selected variable
      filterList() {
        if (!this.filter || this.filter === "None") {
          this.mutableOrders = this.orders;
        } else {
          this.mutableOrders = this.orders.filter((order) => {
            return order.status == this.filter.toLowerCase();
          });
          // if there are no results, it will show the no results card
          if (this.mutableOrders.length == 0) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        }
      },
      // filters through orders by search key
      searchOrders() {
        // checks if search is all whitespace or null
        if (this.search_key && this.search_key.trim().length > 0) {
          let search = this.search_key.trim().toLocaleLowerCase();
          // search buyer names, ids
          this.mutableOrders = this.orders.filter((order) => {
            return (
              order.buyer.name.toLocaleLowerCase().includes(search) ||
              order.orderId.toLocaleLowerCase().includes(search)
            );
          });

          if (this.mutableOrders.length == 0) {
            this.mutableOrders = [];
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        } else {
          this.mutableOrders = this.orders;
          this.noResults = false;
        }
      },
      showReject(order) {
        this.tempRejectOrder = order;
        this.rejectDialog = true;
      },
      rejectOrder() {
        let request = {
          response: this.rejectResponse,
          id: this.tempRejectOrder.id,
          status: "rejecting"
        };
        this.$store.dispatch("setOrderStatus", request);
        this.rejectDialog = false;
        this.tempRejectOrder = null;
      },
      archive(order) {
        let request = {
          id: order.id,
          status: "archived"
        };
        this.deliveryDialog = false;
        this.$store.dispatch("setOrderStatus", request);
      },

      acceptOrder(order) {
        if (!order) {
          return;
        }
        if (!this.bankAccount) {
          this.errorMsg =
            "You haven't setup your bank account yet. In order to get paid for your products, you need to setup your bank account.";
          this.showInvalidAccept = true;
          return;
        }
        if (!this.checkInventory(order)) {
          this.errorMsg =
            "The order amount exceeds inventory, please adjust inventory amount.";
          this.showInvalidAccept = true;
          return;
        }
        let request = {
          id: order.id,
          status: "accepting"
        };
        this.$store.dispatch("setOrderStatus", request);
        this.$router.push("/order/list");
      },
      // makes sure there is enough product amount, returns false is there isn't enough inventory
      checkInventory(order) {
        if (order.products) {
          for (let index = 0; index < order.products.length; index++) {
            let product = order.products[index];
            if (
              product.orderAmount > product.dish.amountLeft &&
              product.dish.amountLeft != -1
            ) {
              return false;
            }
          }
        }
        return true;
      },
      // changes cooking status
      // PARAMS: order id and status (prepping, cooked, and ready)
      updateCookingStatus(order_id, status) {
        this.$store
          .dispatch("setCookingStatus", {
            id: order_id,
            cooking_status: status
          })
          .then(() => {
            this.$store.dispatch("setMessage", {
              title: "Cooking Status",
              body: "saved"
            });
          });
      },

      hasNewMessage(consumer_id) {
        if (this.orderMessages) {
          for (let message in this.orderMessages) {
            if (this.orderMessages[message].from === consumer_id) {
              return true;
            }
          }
        }
        return false;
      },

      toMessages(recipient) {
        this.$store
          .dispatch("getThread", { recipient: recipient })
          .then((thread_id) => {
            this.$router.push("/messages/" + thread_id);
          });
      },
      gotoOrder(order) {
        this.$router.push(`/order/view/${order.sellerUserId}/${order.id}`);
      },
      startCooking(order) {
        return this.$moment(order.deliveryDateTime)
          .add(-30, "m")
          .fromNow();
        //return order.deliveryTime
      },
      statusColor(order) {
        let now = moment();
        let orderTime = this.$moment(order.deliveryDateTime);

        if (order.status === "new") {
          if (orderTime.diff(now, "minutes") > 60 * 5) {
            return "light-green lighten-4";
          }
          if (orderTime.diff(now, "minutes") > 60 * 2) {
            return "light-blue lighten-4";
          }
          if (orderTime.diff(now, "minutes") >= 60) {
            return "red lighten-5";
          }
          if (orderTime.diff(now, "minutes") >= 30) {
            return "red lighten-3";
          }
          return "blue lighten-4";
        }
        if (order.status === "accepted") {
          if (orderTime.diff(now, "minutes") > 60 * 5) {
            return "light-green lighten-4";
          }
          return "red lighten-3";
        }

        return "#ECEFF1";
      },
      deliveryTitle(order) {
        // pick up case
        //order.delivery.touch_free = order.delivery.touch_free || order.delivery.touch_free === undefined
        //console.log('----------->', order.delivery.touch_free )
        // delivery case
        if (order.deliveryMethod === "delivery") {
          return "Delivered";
        }
        return "Picked Up";
      },
      noDeliveryTitle(order) {
        // delivery case
        if (order.deliveryMethod === "delivery") {
          return "No Delivery";
        }
        return "No Pick Up";
      },
      setStatus(order, success) {
        let request = {
          id: order.id
        };
        if (success) {
          // pick up case
          if (order.deliveryMethod === "pickup") {
            request.status = "picking_up";
          }
          // self-delivery case
          else if (order.deliveryMethod === "delivery") {
            request.status = "delivering";
          }
        } else {
          // pick up case
          if (order.deliveryMethod === "pickup") {
            request.status = "no_pickup";
          }
          // self-delivery case
          else if (order.deliveryMethod === "delivery") {
            request.status = "no_delivery";
          }
        }

        if (
          request.status === "delivering" ||
          request.status === "picking_up"
        ) {
          request.deliveryDate = new Date().getTime();
        }

        this.$store.dispatch("setOrderStatus", request);
      },
      showOrderDetail(order) {
        return (
          order.products &&
          (order.status === "new" ||
            order.status === "accepted" ||
            order.status === "submitted" ||
            order.status === "confirmed" ||
            order.status === "no_pickup" ||
            order.status === "picking_up")
        );
      },
      showCookingStatus(order) {
        return (
          this.showOrderDetail(order) &&
          this.isChef &&
          order.status != "new" &&
          order.status != "no_pickup" &&
          order.status != "picking_up"
        );
      },
      showDeliveryActions(order) {
        return (
          order.status != "new" &&
          order.status != "submitted" &&
          this.showOrderDetail(order)
        );
      },
      onDeliveryStaus(order) {
        return order.status === "picking_up";
      },
      onNoDeliveryStaus(order) {
        return order.status === "no_pickup";
      },
      timeToDeliveryInMinutes(order) {
        const dTime = moment(order.deliveryDateTime).utc();
        const now = moment.utc();

        const diff = dTime.diff(now, "hours");
        if (order.orderType != "now" && diff > 1) {
          return {
            allow: false,
            message: `Still ${diff} hours left from delivery time. `
          };
        }
        if (order.orderType != "now" && dTime.diff(now, "minutes") > 30) {
          return {
            allow: true,
            ask: true,
            message: `Still ${dTime.diff(
              now,
              "minutes"
            )} minutes left from delivery time. `
          };
        }
        if (dTime.diff(now, "days") < 0) {
          return {
            allow: false,
            message: `Too late ${-dTime.diff(
              now,
              "days"
            )} days behind from delivery time. `
          };
        }
        if (
          dTime.diff(now, "minutes") < -10 &&
          dTime.diff(now, "minutes") > -60 * 24
        ) {
          return {
            ask: true,
            message: `Too late ${-dTime.diff(
              now,
              "minutes"
            )} minutes behind from delivery time. Do you still want to send a driver?`
          };
        }
        if (dTime.diff(now, "minutes") < -60 * 24) {
          return {
            ask: true,
            message: `Too late ${-dTime.diff(
              now,
              "minutes"
            )} minutes behind from delivery time. `
          };
        }

        return { allow: true };
      },
      onReadyForDelivery(order) {
        this.selected_order_diff = this.timeToDeliveryInMinutes(order);
        this.selected_order = order;
        this.deliveryDialog = true;
      },
      setReadyForDelivery() {
        this.deliveryDialog = false;
        this.$store.dispatch("setDeliveryRequest", {
          id: this.selected_order.id,
          code: "submitting_delivery"
        });
      },
      showReadyForDelivery(order) {
        return (
          order.deliveryMethod === "delivery" &&
          order.status === "accepted" &&
          this.profile.settings.arrangeThirdPartyDelivery
        );
      },
      today(order) {
        return moment(order.deliveryDateTime).isSame(moment(), "day");
      }
    }
  };
</script>

<style scoped type="scss">
  .v-card__text,
  .v-card__title {
    word-break: normal;
  }
</style>
